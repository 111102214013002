.section-footer {
    max-width: 1150px;
    margin: auto;
}

.footer-text {
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    font-size: var(--text-xs);
}

@media screen and (max-width: 480px) {
    .footer-text {
        margin-top: 3rem;
    }
}