.contact-section {
    max-width: 1150px;
    display: flex;
    margin: auto;
    gap: 4rem;
    align-items: center;
}

.contact-info {
    display: flex;
    flex-direction: column;
    flex: .4;
}

.contact-title {
    font-size: var(--text-xl);
}

.contact-description {
    margin-top: 1rem;
    font-size: var(--text-sm);
    color: var(--text-secondary);
}

.contact-email-title {
    font-weight: 400;
    margin-top: 1rem;
    font-size: var(--text-sm);
}

.contact-email-text {
    margin-top: 0.3rem;
    font-size: var(--text-sm);
    color: var(--text-secondary);
}

.contact-social-media {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    gap: 0.7rem;
}

@media screen and (max-width: 480px) {
    .contact-section {
        flex-direction: column;
        align-items: normal;
        margin-top: 10px;
        padding: 0 16px;
        gap: 2rem;
    }
}