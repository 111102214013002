.contact-form {
    display: flex;
    flex-direction: column;
    flex: .6;
    gap: 1.3rem;
}

.contact-form-group {
    width: 100%;
    display: flex;
    gap: 1.3rem;
}

.form-button {
    display: flex;
    width: 100%;
    height: 44px;
    background-color: var(--accent-color);
    color: var(--bgcolor);
    font-weight: 700;
    border-radius: 7px;
    transition: .3s all ease;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 100%;
}

.form-button:hover{
    background-color: #5EC0CD;
}

@media screen and (max-width: 480px) {
    .contact-form-group {
        flex-direction: column;
    }
}