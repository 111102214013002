.textarea-form {
    width: 100%;
    height: 9rem;
    padding: 20px 16px 10px;
    background-color: var(--form-color);
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 0.6rem;
    outline: none;
    font-size: 17px;
    color: var(--text-secondary);
    resize: none;
}

.textarea-field {
    width: 100%;
    position: relative;
}

.textarea-field label {
    position: absolute;
    width: 94%;
    top: 26px;
    left: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .3s;
    color: var(--text-secondary);
    background-color: var(--form-color);
}

textarea:focus ~ label,
textarea:valid ~ label {
    top: 10px;
    left: 14px;
    font-size: 11px;
    padding: 5px 2px 2px 2px;
    color: var(--text-secondary);
    font-weight: 400;
}

.textarea-form:focus {
    outline: 1px solid rgba(255,255,255,.5);
}

.textarea-form::-webkit-scrollbar {
    width: 7px;
}

.textarea-form::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgba(255,255,255,.5);
}