.section-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1150px;
    margin: auto;
}

.skills {
    margin-top: 10px;
    width: 37rem;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
}

@media screen and (max-width: 480px){

    .section-skills {
        margin-top: 10px;
        padding: 0 16px;
    }

    .skills {
        width: 100%;
        padding: 0;
    }
}