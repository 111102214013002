.input-form {
    width: 100%;
    height: 35px;
    padding: 20px 16px 10px;
    background-color: var(--form-color);
    border-radius: 0.6rem;
    outline: none;
    font-size: 95%;
    color: var(--text-secondary);
}

.input-field {
    width: 100%;
    position: relative;
}

.input-field label {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .3s;
    color: var(--text-secondary);
}

.input-form:focus ~ label,
.input-form:valid ~ label {
    top: 7px;
    left: 14px;
    font-size: 10px;
    padding: 0 2px;
    color: var(--text-secondary);
    font-weight: 400;
}

.input-form:focus {
    outline: 1px solid rgba(255,255,255,.5);
}