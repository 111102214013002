.project-detail-section {
    max-width: 1150px;
    margin: auto;
    padding-inline: 1rem;
}

.project-category {
    margin-top: 2rem;
    color: var(--text-secondary);
    font-size: var(--text-sm);
}

.project-title {
    margin-top: 1rem;
    margin-bottom: 0.67em;
    font-size: var(--text-3xl);
}

.project-detail-description {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 4rem;
    font-size: var(--text-sm);
    gap: 2rem;
}

.project-long-description {
    flex: .6;
}
.project-long-description p {
    color: var(--text-secondary);
    margin-top: 0.5rem;
}

.project-detail-stack {
    line-height: 1.8rem;
    flex: .4; 
}

.project-detail-stack h3 {
    border-top: 1px solid var(--text-secondary);
    padding-top: 1.4rem;
    font-size: var(--text-sm);
    margin-bottom: 0.7rem;
}

.project-detail-stack span {
    font-size: var(--text-sm);
    color: var(--text-secondary);
}

.project-detail-links {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.7rem;
    font-size: var(--text-sm);
    font-weight: 700;
}

.icon-link {
    text-decoration: none;
    transition: all .2s ease;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
}

.project-detail-img {
    width: 100%;
    margin-top: 1rem;
}

.project-detail-nav {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 0.5rem;
    margin-top: 2rem;
    margin-bottom: -3rem;
    gap: 4rem;
}

.project-detail-nav > a {
    height: 48px;
    border-radius: 50%;
    padding: 1rem;
    background-color: var(--nav-page-detail-project);
    transition: .3s all ease;
}

.project-detail-nav > a:hover {
    background-color: var(--hover);
}

.project-detai-img-mobile {
    display: none;
    width: 100%;
    margin-top: 1rem;
}

.icon-link:hover{
    opacity: .70;
}

@media screen and (max-width: 480px){

    .project-detail-section {
        padding: 0 16px;
    }

    .project-detail-description {
        flex-direction: column;
    }

    .project-detail-links {
        flex-direction: row;
        gap: 4rem;
    }

    .project-detail-nav {
        margin-bottom: -1rem;
    }

    .project-detail-img {
        display: none;
    }

    .project-detai-img-mobile {
        display: initial;
    }
}