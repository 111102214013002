.skill-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 5px var(--border-card-skill);
    transition: all .4s ease-out;
}

.icono-skill {
    padding-top: 3px;
}

.skill-card:hover {
    box-shadow: 0px 0px 4px 5px var(--accent-color);
}