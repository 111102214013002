.header {
    display: flex;
    background-color: var(--bgcolor);
    position: sticky;
    width: 100%;
    flex-flow: row wrap;
    max-width: 1150px;
    margin: auto;
    padding: 1.5rem 1px;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}

.header-section {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-name {
    z-index: 1;
    text-decoration: none;
    transition: 0.15s all ease-in;
    font-size: var(--text-sm);
    font-weight: 700;
}

.en {
    z-index: 1;
    cursor: pointer;
    opacity: 0.5;
    transition: all .3s ease-out;
}

.es {
    z-index: 1;
    cursor: pointer;
    opacity: 1;
    transition: all .3s ease-out;
}

.header-name:hover {
    opacity: 0.75;
}

.visible {
    top: 0;
    transition: top .4s ease-out;
}

.hidden {
    top: -80px;
    transition: top .4s ease-out;
}

@media screen and (max-width: 480px){

    .header {
        padding: 1rem 1.5rem;
        justify-content: space-between;
    }

    .visible {
        top: 0;
        z-index: 1;
    }

    .hidden {
        top: -80px;
        transition: top .4s ease-out;
    }
}