/* Reseteamos los margin y paddings de todas las etiquetas */
* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: baseline;
  color: var(--text-primary);
}

body {
  min-height: 100vh;
  font-size: 100%;
  background-color: var(--bgcolor);
}

.accent-color {
  color: var(--accent-color);
}